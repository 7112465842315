import React     from "react";
import PropTypes from "prop-types";
import axios     from "axios";

import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";

import Button   from "./form/Button";
import Label    from "./form/Label";
import Modal    from "./models/Modal";

export default class AdminStaticQuestionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      learnMore: this.props.learnMore,
      message: null,
      modalContent: null,
      step: 3
    };

    this.save = this.save.bind(this);
    this.showStep = this.showStep.bind(this);
    this.handleClick = this.handleClick.bind(this);
  };

  /**
   * Remove the save message after 5 seconds.
   */
  componentDidUpdate(prevProps, prevState) {
    const _this = this;

    if(this.state.message){
      setTimeout(function(){
        _this.setState(Object.assign({}, _this.state, {message: null}));
      }, 5000);
    }
  };

  handleClick(e) {
    let target;
    if(e.target.dataset.hasOwnProperty("action")){
      target = e.target;
    } else {
      target = e.target.parentElement;
    }

    const lms = this.state.learnMore;
    const id  = target.id.split("_")[1];
    const lm  = lms.find(l => l.id == id);

    switch(target.dataset.action){
      case "close-modal":
        this.setState(Object.assign({}, this.state, {modalContent: null}));
        break;
      
      case "open-modal":
        const modalContent = (<div id="wysiwyg-container" data-id={lm.id}>
          <ReactTrixRTEToolbar toolbarId="wysiwyg-toolbar" toolbarActions={["bold", "italic", "link", "heading1", "quote", "bullet", "number", "outdent", "indent", "undo", "redo"]} />
          <ReactTrixRTEInput toolbarId="wysiwyg-toolbar" defaultValue={lm.description} />

          <button onClick={this.handleClick} data-action="close-modal"  id={`lm_${lm.id}_close_modal`} className="btn prev">Cancel</button>
          <button onClick={this.handleClick} data-action="save-wysiwyg" id={`lm_${lm.id}_save_modal`} className="btn next">Save</button>
        </div>);

        this.setState(Object.assign({}, this.state, {modalContent: modalContent}));
        break;

      case "save-wysiwyg":
        lm.description = document.getElementsByTagName("TRIX-EDITOR")[0].innerHTML;
        this.setState(Object.assign({}, this.state, {learnMore: lms, modalContent: null}));
        break;

      case "toggle":
        if(lm.expanded !== true){
          lm.expanded = true;
        } else {
          lm.expanded = false;
        }

        this.setState(Object.assign({}, this.state, {learnMore: lms}));
        break;
    }
  };

  save(e) {
    e.preventDefault();
    const _this = this;

    axios({
      url:     "/admin/static_questions/update_all", 
      method:  "PATCH",
      data:    {learn_more: this.state.learnMore},
      headers: { 'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content }
    }).then(function(response) {
      window.scrollTo(0,0);
      if(response.data.error) {
        _this.setState(Object.assign({}, _this.state, {message: "Cannot save static questions. Please try again."}))
      } else {
        _this.setState(Object.assign({}, _this.state, {message: "Static Questions Saved!"}));
      }
    });
  };

  showStep(e, step) {
    e.preventDefault();
    this.setState(Object.assign({}, this.state, {step: parseInt(step)}));
  };

  render () {
    let modal;
    if(this.state.modalContent) {
      modal = (<Modal owner={this}>
        {this.state.modalContent}
      </Modal>);
    }

    const _this = this;
    const questions = this.props.questions[this.state.step].map(function(q){
      const learnMore = _this.state.learnMore.find(lm => lm.target === q.id);

      if(learnMore) {
        return(<section className="risk" aria-expanded={learnMore.expanded || false} key={q.id}>
          <div id={`lm_${learnMore.id}_header`} className="risk-header" onClick={_this.handleClick} data-action="toggle" style={{cursor: "pointer"}}>
            <h2>{q.label}</h2>
            <span className="expander-icon open"><i className="arrow down"></i></span>
            <span className="expander-icon close"><i className="arrow up"></i></span>
          </div>

          <div className="risk-body">
            <div className="learn-more-field">
              <Label htmlFor={`lm_${learnMore.id}_launcher`} text={"<strong>Learn More</strong>"} />
              <div
                className="wysiwyg-preview"
                onClick={_this.handleClick}
                id={`lm_${learnMore.id}_launcher`}
                data-action="open-modal"
                dangerouslySetInnerHTML={{__html: learnMore.description}}
              ></div>
            </div>
          </div>
        </section>);
      } else {
        return(<div key={q.id}></div>);
      }
    });

    return (<React.Fragment>
      <h1>Manage Static Questions</h1>
      <div className="message">{this.state.message}</div>
      <form>
        <div id="question-labels">
          <button className={`btn next${this.state.step === 3 ? " active" : ""}`} onClick={(e) => _this.showStep(e, 3)}>Procedures Step</button>
          <button className={`btn next${this.state.step === 4 ? " active" : ""}`} onClick={(e) => _this.showStep(e, 4)}>Training Step</button>
          <button className={`btn next${this.state.step === 5 ? " active" : ""}`} onClick={(e) => _this.showStep(e, 5)}>Responsibility Step</button>
          <button className={`btn next${this.state.step === 6 ? " active" : ""}`} onClick={(e) => _this.showStep(e, 6)}>Other Info Step</button>
        </div>

        <div id="questions">{questions}</div>
        <Button text="Save" onClick={this.save} classes="next btn" />
      </form>
      {modal}
    </React.Fragment>);
  };
}

AdminStaticQuestionsPage.propTypes = {
  questions: PropTypes.object.isRequired,
  learnMore: PropTypes.array.isRequired
};
