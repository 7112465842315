import React     from "react";
import PropTypes from "prop-types";
import axios     from "axios";

import Button from "../form/Button";

export default class ResultsStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = { message: "", plan: "", email: null };

    this.prevStep = this.prevStep.bind(this);
    this.handleClick = this.handleClick.bind(this);
  };

  /**
   * This displays the PDF as HTML. This entire piece is put on a small delay and timer.
   * If the user changes data in the previous step, then clicks to this setp, they will not see their
   * changes from the previous step without the delay. We need to ensure that the GET for the plan HTML
   * happens after the PATCH to save the plan completes. We do this with an okToPreview boolean that
   * the PlanPage toggles while saving.
   */
  componentDidMount() {
    const _this = this;
    let interval;
    
    setTimeout(function(){
      interval = setInterval(function(){
        if(_this.props.root.state.okToPreview === true) {
          clearInterval(interval);
          _this.previewPlan();
        }
      }, 50);
    }, 50);
  };

  handleClick(e) {
    e.preventDefault();
    const _this = this;

    switch (e.target.dataset.action) {
      case "email":
        this.state.email = window.prompt("Email Address");
        if(this.state.email) {
          axios({
            url: `/plans/${this.props.root.state.id}/email?to=${this.state.email}`,
            method: "GET",
            headers: {
              "X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content
            }
          }).then(function(response) {
            window.scrollTo(0,0);
            _this.setState(Object.assign({}, _this.state, {email: null, message: "Email sent!"}));
          }).catch(function(error) {
            window.scrollTo(0,0);
            _this.setState(Object.assign({}, _this.state, {email: null, message: "Cannot send email. Please try again."}));
          });
        }
        break;

      case "pdf":
        window.open(`/plans/${this.props.root.state.id}.pdf`);
        break;

      case "print":
        window.print();
        break;
    }
  }

  previewPlan() {
    const _this = this;

    axios({
      url: `/plans/${this.props.root.state.id}.pdf?debug=true`,
      method: "GET"
    }).then(function(response) {
        _this.setState(Object.assign({}, _this.state, {plan: response.data}));
    }).catch(function(error) {
      _this.setState(Object.assign({}, _this.state, {message: "Cannot load completed plan. Please reload the page, or use one of the buttons to retrieve your plan."}));
    });
  };

  emailPlan() {
    const _this = this;

    axios({
      url: `/plans/${this.props.root.state.id}.pdf?debug=true`,
      method: "GET"
    }).then(function(response) {
        _this.setState(Object.assign({}, _this.state, {plan: response.data}));
    }).catch(function(error) {
      _this.setState(Object.assign({}, _this.state, {message: "Cannot load completed plan. Please reload the page, or use one of the buttons to retrieve your plan."}));
    });
  };

  prevStep(e) {
    e.preventDefault();
    this.props.root.goToStep("previous");
  }

  render() {
    return (
      <React.Fragment>
        <h2>{this.props.root.state.saveData["name"]}</h2>
        
        <div className="message">{this.state.message}</div>

        <p className="introText">
          What is known about how COVID-19 spreads and can be controlled is
          evolving. As new information is learned, this COVID-19 Planning Tool
          and related Construction Clearinghouse will be updated. Please review
          your plan periodically to ensure it includes the most up-to-date
          information and is being implemented properly.
        </p>

        <div dangerouslySetInnerHTML={{__html: this.state.plan}}></div>

        <Button text={"Back"} classes="btn back" onClick={this.prevStep} />
        <button className="btn pdf" onClick={this.handleClick} data-action="pdf">Download PDF</button>
        <button className="btn email" onClick={this.handleClick} data-action="email">Email Plan</button>
        <button className="btn print" onClick={this.handleClick} data-action="print">Print Plan</button>
      </React.Fragment>
    );
  }
}

ResultsStep.propTypes = {
  root: PropTypes.object.isRequired
};
