import React     from "react";
import PropTypes from "prop-types";

export default class Option extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    return (<option value={this.props.label} key={this.props.id}>{this.props.label}</option>);
  };
}

Option.propTypes = {
  id:    PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
