import React     from "react";
import PropTypes from "prop-types";

import Option from "./Option";

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: this.props.value || "" };
    this.handleChange = this.handleChange.bind(this);
  };

  componentWillUnmount(){
    if(this.props.root) {
      this.props.root.updateSaveData(this.exportData());
    }
  };

  handleChange(e) {
    this.setState(Object.assign({}, this.state, {value: e.target.value}));
  };

  exportData(){
    return {
      [this.props.id]: this.state.value
    }
  };

  render() {
    const _this = this;

    let emptyOption;
    if(this.props.empty){
      emptyOption = (<Option label={""} id={"empty"} key={"empty"} />);
    }

    return (<select id={this.props.id} className={this.props.classes} onChange={this.handleChange} value={this.state.value}>
      { emptyOption }
      { _this.props.options.map(option => <Option label={option.label} id={option.id} key={option.id} />) }
    </select>);
  };
}

Select.propTypes = {
  id:      PropTypes.string.isRequired,
  classes: PropTypes.string,
  root:    PropTypes.object,
  options: PropTypes.array.isRequired,
  empty:   PropTypes.bool.isRequired,
  value:   PropTypes.string.isRequired
};
