import React     from "react";
import PropTypes from "prop-types";

export default class Radio extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: this.props.value };
    this.handleChange = this.handleChange.bind(this);
  };

  componentWillUnmount(){
    if(this.props.root) {
      this.props.root.updateSaveData(this.exportData());
    }
  };

  handleChange(e) {
    this.setState(Object.assign({}, this.state, {value: e.target.value}));
  };

  exportData(){
    return {
      [this.props.id]: this.state.value
    }
  };

  render() {
    const _this = this;

    return (<React.Fragment>
      {
        _this.props.options.map(function(option, i){
          return(<React.Fragment key={i}>
            <input type="radio" checked={_this.state.value === option} value={option} name={_this.props.id} onChange={_this.handleChange} className={_this.props.classes} />
            <span className="label">{option}</span>
          </React.Fragment>);
        })
      }
    </React.Fragment>);
  };
}

Radio.propTypes = {
  id:      PropTypes.string.isRequired,
  value:   PropTypes.string.isRequired,
  classes: PropTypes.string,
  root:    PropTypes.object
};
