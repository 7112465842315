import React     from "react";
import PropTypes from "prop-types";
import axios     from "axios";

import Button from "./form/Button";
import Label  from "./form/Label";

export default class AdminUsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message: null, user: Object.assign(this.props.user, {password: "", password_confirmation: ""}) };
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
  };

  /**
   * Remove the save message after 10 seconds.
   */
  componentDidUpdate(prevProps, prevState) {
    const _this = this;

    if(this.state.message){
      setTimeout(function(){
        _this.setState(Object.assign({}, _this.state, {message: null}));
      }, 10000);
    }
  };

  /**
   * A field's id attribute matches its key in this.state.users.
   */
  handleChange(e) {
    const newUser = Object.assign(this.state.user, {[e.target.id]: e.target.value});
    this.setState(Object.assign({}, this.state, {user: newUser}));
  };

  save(e) {
    e.preventDefault();

    const _this = this;
    const persisted = this.state.user.id !== null;
    let url, method, newUser;

    if(persisted) {
      url = `/admin/users/${this.state.user.id}`;
      method = "PATCH";
    } else {
      url = "/admin/users";
      method = "POST";
    }

    axios({
      url:     url, 
      method:  method,
      data:    {user: this.state.user},
      headers: { 'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content }
    }).then(function(response) {
      if(response.data.error) {
        let message = "";

        for(const [key, value] of Object.entries(response.data.error)) {
          message += `${key} ${value}. `;
        }
        _this.setState(Object.assign({}, _this.state, {message: message}));
      } else {
        if(persisted === false) {
          newUser = Object.assign(_this.state.user, {id: response.data.id, password: "", password_confirmation: ""});
          _this.setState(Object.assign({}, _this.state, {message: "User created!", user: newUser}));

          window.history.pushState("", "", `/admin/users/${response.data.id}/edit`);
        } else {
          newUser = Object.assign(_this.state.user, {password: "", password_confirmation: ""});
          _this.setState(Object.assign({}, _this.state, {message: "User updated!", user: newUser}));
        }
      }
    });
  };

  render() {
    return (<React.Fragment>
      <div className="message">{this.state.message}</div>
      <form>
        <ul>
          <li>
            <Label htmlFor="user_email" text="<b>Email</b>" /><br />
            <input type="text" id="email" value={this.state.user.email} onChange={this.handleChange} />
          </li>
          <li>
            <Label htmlFor="user_email" text="<b>Role</b>" /><br />
            <select id="role" value={this.state.user.role} onChange={this.handleChange}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </li>
          <li>
            <Label htmlFor="user_password" text="<b>Password</b> (<em>leave blank unless you want to change the user's password</em>)" /><br />
            <input type="password" id="password" value={this.state.user.password} onChange={this.handleChange} />
          </li>
          <li>
            <Label htmlFor="user_password_confirmarion" text="<b>Password Confirmation</b> (<em>leave blank unless you want to change the user's password</em>)" /><br />
            <input type="password" id="password_confirmation" value={this.state.user.password_confirmation} onChange={this.handleChange} />
          </li>
          <li>
            <Button text="Save" onClick={this.save} classes="btn" />
          </li>
        </ul>
      </form>
    </React.Fragment>);
  }
}

AdminUsersPage.propTypes = {
  user: PropTypes.object.isRequired
};
