import React     from "react";
import ReactDOM  from "react-dom";
import PropTypes from "prop-types";

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    return ReactDOM.createPortal(
      <React.Fragment>
        <div id="page-mask"></div>
        <div id="open-modal" className="modal-window">
          {this.props.children}
        </div>
      </React.Fragment>,
    document.getElementById("modal-container"));
  };
}

Modal.propTypes = {
  children: PropTypes.array.isRequired
};
