import React     from "react";
import PropTypes from "prop-types";

import Text     from "../form/Text";
import Checkbox from "../form/Checkbox";
import Label    from "../form/Label";

export default class Other extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { other: false };
    this.checkboxRef = React.createRef();
  };

  componentDidMount() {
    this.checkOther();
  };

  checkOther() {
    const checkId  = `s${this.props.root.state.step}_r${this.props.risk.props.id}_oc`;
    const checkbox = document.getElementById(checkId);

    if((this.checkboxRef.current.state.checked === true && this.state.other === false) ||
       (this.checkboxRef.current.state.checked === false && this.state.other === true)) {
      this.state.other = !this.state.other;
      this.forceUpdate();
    }
  };

  render() {
    const checkId = `s${this.props.root.state.step}_r${this.props.risk.props.id}_oc`;
    const inputId = `s${this.props.root.state.step}_r${this.props.risk.props.id}_oi`;

    let otherInput;
    
    if(this.state.other === true) {
      otherInput = (<Text id={inputId} value={this.props.root.state.saveData[inputId] || ""} classes={"text"} root={this.props.root} />);
    }

    return (<div className="other">
      <div className="control-measure">
        <Checkbox ref={this.checkboxRef} checked={this.props.root.state.saveData[checkId] || false} value={"1"} classes={"checkbox"} id={checkId} root={this.props.root} risk={this.props.risk} other={this} />
        <Label htmlFor={checkId} text={"Other"} />
      </div>
      {otherInput}
    </div>);
  };
}

Other.propTypes = {
  risk: PropTypes.object.isRequired,
  root: PropTypes.object.isRequired
};