import React from "react";
import PropTypes from "prop-types";

import Button from "../form/Button";
import Label from "../form/Label";

export default class StaticStep extends React.Component {
  constructor(props) {
    super(props);

    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
  }

  prevStep(e) {
    e.preventDefault();
    this.props.root.goToStep("previous");
  }

  nextStep(e) {
    e.preventDefault();
    this.props.root.goToStep("next");
  }

  render() {
    const _this = this;

    return (
      <React.Fragment>
        <h2>{this.props.title}</h2>
        <p className="step-intro">{this.props.introText}</p>
        {this.props.questions.map(q =>
          _this.props.root.renderStaticQuestion(q)
        )}
        <Button text={"Back"} classes="btn prev" onClick={this.prevStep} />
        <Button
          text={this.props.buttonText}
          classes="btn next"
          onClick={this.nextStep}
        />
      </React.Fragment>
    );
  }
}

StaticStep.propTypes = {
  questions: PropTypes.array.isRequired,
  root: PropTypes.object.isRequired
};
