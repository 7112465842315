import React     from "react";
import PropTypes from "prop-types";

export default class Label extends React.Component {
  constructor(props) {
    super(props);
  };

  shouldComponentUpdate() {
    return false;
  };

  render() {
    return (<label htmlFor={this.props.htmlFor}><span dangerouslySetInnerHTML={{__html: this.props.text}} /></label>);
  };
}

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  text:    PropTypes.string.isRequired
};
