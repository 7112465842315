import React     from "react";
import PropTypes from "prop-types";
import axios     from "axios";

import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";

import Button from "./form/Button";

export default class AdminEditContentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageContent: this.props.pageContent,
      message: null
    };

    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getHeaderFor = this.getHeaderFor.bind(this);
  };

  /**
   * Remove the save message after 5 seconds.
   */
  componentDidUpdate(prevProps, prevState) {
    const _this = this;

    if(this.state.message){
      setTimeout(function(){
        _this.setState(Object.assign({}, _this.state, {message: null}));
      }, 5000);
    }
  };

  handleChange(e, newValue) {
    let pageContent = this.state.pageContent;
    let piece = pageContent.find(pc => pc.id == e.target.closest("[data-id]").dataset.id);

    piece.text = newValue;
    this.setState(Object.assign({}, this.state, {pageContent: pageContent}));
  };

  getHeaderFor(key) {
    let header;
    switch(key) {
      case "homepage":
        header = "Homepage";
        break;

      case "why":
      header = "Why Create A Plan?";
        break;
      
      case "how":
      header = "How It Works";
        break;
      
      case "privacy":
      header = "Provacy Policy";
        break;
      
      case "disclaimer":
      header = "Disclaimer";
        break;
      
      case "contact":
      header = "Contact Us";
        break;
    };
    return header;
  }

  save(e) {
    e.preventDefault();
    const _this = this;

    axios({
      url:     "/admin/page_content/update_all", 
      method:  "PATCH",
      data:    {page_content: this.state.pageContent},
      headers: { 'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content }
    }).then(function(response) {
      window.scrollTo(0, 0);
      if(response.data.error) {
        _this.setState(Object.assign({}, _this.state, {message: "Cannot save pagecontent. Please try again."}))
      } else {
        _this.setState(Object.assign({}, _this.state, {message: "Page Content Saved!"}));
      }
    });
  };

  render() {
    const _this = this;

    const content = this.state.pageContent.map(function(pc) {
      return(<div className="page-content-editor" key={pc.id} data-id={pc.id}>
        <h3>{_this.getHeaderFor(pc.key)}</h3>
        <ReactTrixRTEToolbar toolbarId="wysiwyg-toolbar" toolbarActions={["bold", "italic", "link", "heading1", "quote", "bullet", "number", "outdent", "indent", "undo", "redo"]} />
        <ReactTrixRTEInput toolbarId="wysiwyg-toolbar" defaultValue={pc.text} onChange={_this.handleChange} />
      </div>);
    });

    return (<React.Fragment>
      <h1 key="title">Manage Page Content</h1>
      <div ref={this.messageRef} className="message">{this.state.message}</div>
      {content}
      <Button key="save" text="Save" onClick={this.save} classes="btn next" />
    </React.Fragment>);
  }
}
