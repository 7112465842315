import React from "react"
import PropTypes from "prop-types"

export default class Textarea extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: this.props.value };
    this.handleChange = this.handleChange.bind(this);
  };

  componentWillUnmount(){
    if(this.props.root) {
      this.props.root.updateSaveData(this.exportData());
    }
  };

  handleChange(e) {
    this.setState(Object.assign({}, this.state, {value: e.target.value}));
  };

  exportData(){
    return {
      [this.props.id]: this.state.value
    }
  };

  render() {
    return (<textarea id={this.props.id} value={this.state.value} onChange={this.handleChange} className={this.props.classes}></textarea>)
  };
}

Textarea.propTypes = {
  classes: PropTypes.string,
  id:      PropTypes.string.isRequired,
  root:    PropTypes.object,
  value:   PropTypes.string.isRequired
};
