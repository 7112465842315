import React from "react";
import PropTypes from "prop-types";

import Risk from "../models/Risk";
import Button from "../form/Button";

export default class RiskStep extends React.Component {
  constructor(props) {
    super(props);

    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
  }

  prevStep(e) {
    e.preventDefault();
    this.props.root.goToStep("previous");
  }

  nextStep(e) {
    e.preventDefault();
    this.props.root.goToStep("next");
  }

  render() {
    let backButton;
    if (this.props.root.state.step !== 1) {
      backButton = (
        <Button text={"Back"} classes="btn prev" onClick={this.prevStep} />
      );
    }

    return (
      <React.Fragment>
        <h2>
          Step 1: Identify where your employees could be exposed to COVID-19 and the control measures that will be used to protect them and prevent exposure.

        </h2>
        <p className="step-intro">
          The following is a list of areas on a job site and in an
          office/trailer where individuals may be exposed to COVID-19 in the air
          and/or on surfaces. Select all the exposure risks that you expect
          will be present on your job site. As you select an exposure risk, a
          list of possible controls to prevent exposure will appear. Please
          select all the controls that you will use. If an exposure risk or
          control is not listed, please use the ‘Other’ option to fill in ones
          of your own and use the text box to include additional information on
          the location of the risks and how controls will be implemented. The
          blue information icon will take you to additional information on the
          risk or control measure.
        </p>
        <h3>{this.props.riskType}</h3>

        {this.props.risks.map(risk => (
          <Risk
            key={risk.id}
            id={risk.id}
            name={risk.name}
            learnMoreId={risk.learnMoreId}
            root={this.props.root}
          />
        ))}

        {backButton}
        <Button
          text={this.props.buttonText}
          classes="btn next"
          onClick={this.nextStep}
        />
      </React.Fragment>
    );
  }
}

RiskStep.propTypes = {
  buttonText: PropTypes.string.isRequired,
  risks: PropTypes.array.isRequired,
  riskType: PropTypes.string.isRequired,
  root: PropTypes.object.isRequired
};
