import React     from "react";
import PropTypes from "prop-types";

export default class Button extends React.Component {
  constructor(props) {
    super(props);
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.text !== nextProps.text;
  };

  render() {
    return (<React.Fragment>
      <button onClick={this.props.onClick} className={this.props.classes}>{this.props.text}</button>
    </React.Fragment>);
  };
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  classes: PropTypes.string
};
