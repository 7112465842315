import React from "react";
import PropTypes from "prop-types";

import Checkbox from "../form/Checkbox";
import Label    from "../form/Label";

export default class ControlMeasure extends React.Component {
  constructor(props) {
    super(props);
  };

  shouldComponentUpdate() {
    return false;
  };

  render() {
    const _this = this;
    const inputId = `s${this.props.root.state.step}_r${this.props.risk.props.id}_cm${this.props.id}`;
    const learnMore = this.props.root.props.learnMore.find(lm => lm.id == _this.props.learnMoreId);

    let learnMoreLink;
    if(learnMore.description !== null) {
      learnMoreLink = (<a onClick={this.props.root.handleClick} data-id={this.props.learnMoreId} data-action="open-modal" className="more">i</a>);
    }

    return (<div className="control-measure">
      <Checkbox checked={this.props.root.state.saveData[inputId] || false} value={this.props.id.toString()} classes="checkbox" id={inputId} root={this.props.root} risk={this.props.risk} />
      <Label htmlFor={inputId} text={this.props.description} />
      {learnMoreLink}
    </div>);
  }
}

ControlMeasure.propTypes = {
  id:          PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  risk:        PropTypes.object.isRequired,
  root:        PropTypes.object.isRequired
};
