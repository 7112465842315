import React     from "react";
import PropTypes from "prop-types";
import axios     from "axios";

import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";

import Button   from "./form/Button";
import Label    from "./form/Label";
import Modal    from "./models/Modal";

export default class AdminControlMeasuresPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      controlMeasures: this.props.controlMeasures,
      message: null,
      modalContent: null
    };

    this.add  = this.add.bind(this);
    this.save = this.save.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleClick  = this.handleClick.bind(this);
  };

  /**
   * Remove the save message after 5 seconds.
   */
  componentDidUpdate(prevProps, prevState) {
    const _this = this;

    if(this.state.message){
      setTimeout(function(){
        _this.setState(Object.assign({}, _this.state, {message: null}));
      }, 5000);
    }
  };

  handleChange(e) {
    const cms = this.state.controlMeasures;
    const id  = e.target.id.split("_")[1];
    const cm  = cms.find(c => c.id == id);

    switch(e.target.dataset.field){
      case "description":
        cm[e.target.dataset.field] = e.target.value
        break;
    }

    this.setState(Object.assign({}, this.state, {controlMeasures: cms}));
  };

  handleClick(e) {
    let target;
    if(e.target.dataset.hasOwnProperty("action")){
      target = e.target;
    } else {
      target = e.target.parentElement;
    }

    const cms = this.state.controlMeasures;
    const id  = target.id.split("_")[1];
    const cm  = cms.find(c => c.id == id);

    switch(target.dataset.action){
      case "close-modal":
        this.setState(Object.assign({}, this.state, {modalContent: null}));
        break;
      
      case "open-modal":
        const modalContent = (<div id="wysiwyg-container" data-id={cm.id}>
          <ReactTrixRTEToolbar toolbarId="wysiwyg-toolbar" toolbarActions={["bold", "italic", "link", "heading1", "quote", "bullet", "number", "outdent", "indent", "undo", "redo"]} />
          <ReactTrixRTEInput toolbarId="wysiwyg-toolbar" defaultValue={cm.learnMore} />

          <button onClick={this.handleClick} data-action="close-modal"  id={`cm_${cm.id}_close_modal`} className="btn prev">Cancel</button>
          <button onClick={this.handleClick} data-action="save-wysiwyg" id={`cm_${cm.id}_save_modal`} className="btn next">Save</button>
        </div>);

        this.setState(Object.assign({}, this.state, {modalContent: modalContent}));
        break;

      case "save-wysiwyg":
        cm.learnMore = document.getElementsByTagName("TRIX-EDITOR")[0].innerHTML;
        this.setState(Object.assign({}, this.state, {controlMeasures: cms, modalContent: null}));
        break;

      case "toggle-cm":
        if(cm.expanded !== true){
          cm.expanded = true;
        } else {
          cm.expanded = false;
        }

        this.setState(Object.assign({}, this.state, {controlMeasures: cms}));
        break;
    }
  };

  /**
   * Adds a new control measure
   */
  add(e) {
    e.preventDefault();
    const newControlMeasure  = {id: `new--${this.state.controlMeasures.length + 1}`, description: ""};
    this.setState(Object.assign({}, this.state, {controlMeasures: this.state.controlMeasures.concat(newControlMeasure)}));
  };

  /**
   * Change the description for a control measure.
   */
  updateDescription(e) {
    const controlMeasures = this.state.controlMeasures;
    const id = e.target.id.split("_")[1];
    const cm = controlMeasures.find(cm => cm.id == id);

    cm.description = e.target.value
    this.setState(Object.assign({}, this.state, {controlMeasures: controlMeasures}));
  };

  save(e) {
    e.preventDefault();
    const _this = this;

    axios({
      url:     "/admin/control_measures/update_all", 
      method:  "PATCH",
      data:    {control_measures: this.state.controlMeasures},
      headers: { 'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content }
    }).then(function(response) {
      window.scrollTo(0,0);
      if(response.data.error) {
        _this.setState(Object.assign({}, _this.state, {message: "Cannot save control measures. Please try again."}))
      } else {
        _this.setState(Object.assign({}, _this.state, {message: "Control Measures saved!", controlMeasures: response.data}));
      }
    });
  };

  render () {
    let modal;
    if(this.state.modalContent) {
      modal = (<Modal owner={this}>
        {this.state.modalContent}
      </Modal>);
    }

    return (<React.Fragment>
      <h1>Manage Control Measures</h1>
      <div className="message">{this.state.message}</div>
      <form>
        {this.renderControlMeasures()}

        <Button text="Add Control Measure" onClick={this.add} classes="prev btn" />
        <Button text="Save" onClick={this.save} classes="next btn" />
      </form>
      {modal}
    </React.Fragment>);
  };

  renderControlMeasures() {
    const _this = this;

    return this.state.controlMeasures.map(function(cm){
      return(<section className="risk" aria-expanded={cm.expanded} key={cm.id}>
        <div id={`cm_${cm.id}_header`} className="risk-header" onClick={_this.handleClick} data-action="toggle-cm" style={{cursor: "pointer"}}>
          <h2>{cm.description}</h2>
          <span className="expander-icon open"><i className="arrow down"></i></span>
          <span className="expander-icon close"><i className="arrow up"></i></span>
        </div>

        <div className="risk-body">
          <div className="description-field">
            <Label htmlFor={`cm_${cm.id}_description`} text={"<strong>Description</strong>"} />
            <input
              type="text"
              id={`cm_${cm.id}_description`}
              name={`control_measures[${cm.id}][description]`}
              value={cm.description}
              onChange={_this.handleChange}
              data-field="description"
              data-id={cm.id}
            />
          </div>

          <div className="learn-more-field">
            <Label htmlFor={`cm_${cm.id}_learn_more`} text={"<strong>Learn More</strong>"} />
            <div
              className="wysiwyg-preview"
              onClick={_this.handleClick}
              id={`cm_${cm.id}_learn_more_launcher`}
              data-action="open-modal"
              dangerouslySetInnerHTML={{__html: cm.learnMore}}
            ></div>
          </div>
        </div>
      </section>);
    });
  };
}

AdminControlMeasuresPage.propTypes = {
  controlMeasures: PropTypes.array.isRequired
};
