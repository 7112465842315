import React     from "react";
import PropTypes from "prop-types";
import axios     from "axios";

import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";

import Button   from "./form/Button";
import Hidden   from "./form/Hidden";
import Label    from "./form/Label";
import Modal    from "./models/Modal";

export default class AdminRisksPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      risks: this.props.risks.map(risk => Object.assign(risk, {persisted: true})),
      message: null,
      modalContent: null
    };

    this.add  = this.add.bind(this);
    this.save = this.save.bind(this);
    
    this.handleClick  = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  };

  /**
   * Remove the save message after 5 seconds.
   */
  componentDidUpdate(prevProps, prevState) {
    const _this = this;

    if(this.state.message){
      setTimeout(function(){
        _this.setState(Object.assign({}, _this.state, {message: null}));
      }, 5000);
    }
  };

  /**
   * Adds a new control measure
   */
  add(e) {
    e.preventDefault();
    const newRisk  = {
      id:              `new--${this.state.risks.length + 1}`,
      name:            "New Risk",
      persisted:       false,
      expanded:        true,
      controlMeasures: []
    };
    this.setState(Object.assign({}, this.state, {risks: this.state.risks.concat(newRisk)}));
  };

  handleChange(e) {
    const risks = this.state.risks;
    const id = e.target.id.split("_")[1];
    const risk = risks.find(risk => risk.id == id);

    switch(e.target.dataset.field){
      case "name":
      case "riskType":
        risk[e.target.dataset.field] = e.target.value
        break;

      case "control_measure":
        if(e.target.checked) {
          risk.controlMeasures.push(parseInt(e.target.value));
        } else {
          risk.controlMeasures = risk.controlMeasures.filter(cm => cm != e.target.value);
        }
        break;
    }

    this.setState(Object.assign({}, this.state, {risks: risks}));
  };

  handleClick(e) {
    let target;
    if(e.target.dataset.hasOwnProperty("action")){
      target = e.target;
    } else {
      target = e.target.parentElement;
    }

    const risks = this.state.risks;
    const id = target.id.split("_")[1];
    const risk = risks.find(risk => risk.id == id);

    switch(target.dataset.action){
      case "close-modal":
        this.setState(Object.assign({}, this.state, {modalContent: null}));
        break;
      
      case "open-modal":
        const modalContent = (<div id="wysiwyg-container" data-id={risk.id}>
          <ReactTrixRTEToolbar toolbarId="wysiwyg-toolbar" toolbarActions={["bold", "italic", "link", "heading1", "quote", "bullet", "number", "outdent", "indent", "undo", "redo"]} />
          <ReactTrixRTEInput toolbarId="wysiwyg-toolbar" defaultValue={risk.learnMore} />

          <button onClick={this.handleClick} data-action="close-modal"  id={`risk_${risk.id}_close_modal`} className="btn prev">Cancel</button>
          <button onClick={this.handleClick} data-action="save-wysiwyg" id={`risk_${risk.id}_save_modal`} className="btn next">Save</button>
        </div>);

        this.setState(Object.assign({}, this.state, {modalContent: modalContent}));
        break;

      case "save-wysiwyg":
        risk.learnMore = document.getElementsByTagName("TRIX-EDITOR")[0].innerHTML;
        this.setState(Object.assign({}, this.state, {risks: risks, modalContent: null}));
        break;

      case "toggle-risk":
        if(risk.expanded !== true){
          risk.expanded = true;
        } else {
          risk.expanded = false;
        }

        this.setState(Object.assign({}, this.state, {risks: risks}));
        break;
    }
  };

  save(e) {
    e.preventDefault();
    const _this = this;

    axios({
      url:     "/admin/risks/update_all", 
      method:  "PATCH",
      data:    {risks: this.state.risks},
      headers: { 'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content }
    }).then(function(response) {
      window.scrollTo(0, 0);
      if(response.data.error) {
        _this.setState(Object.assign({}, _this.state, {message: "Cannot save plan. Please try again."}))
      } else {
        _this.setState(Object.assign({}, _this.state, {message: "Plan Saved!", risks: response.data.map(risk => Object.assign(risk, {persisted: true}))}));
      }
    });
  };

  render () {
    return (<React.Fragment>
      <div ref={this.messageRef} className="message">{this.state.message}</div>
      <form>
        {this.renderRisks()}
        <Button text="Add Risk" onClick={this.add} classes="btn prev" />
        <Button text="Save" onClick={this.save} classes="btn next" />
      </form>
    </React.Fragment>);
  };

  renderRisks() {
    const _this = this;
    let rows = [], modal;

    if(this.state.modalContent) {
      modal = (<Modal owner={this}>
        {this.state.modalContent}
      </Modal>);
    }

    const risks = this.state.risks.map(function(risk){
      const controlMeasures = _this.props.controlMeasures.map(function(cm){
        return (<div key={cm.id}>
          <input 
            type="checkbox"
            id={`risk_${risk.id}_cm_${cm.id}`}
            value={cm.id}
            checked={risk.controlMeasures.indexOf(cm.id) > -1}
            onChange={_this.handleChange}
            data-field="control_measure"
            data-id={risk.id}
          />
          <Label htmlFor={`risk_${risk.id}_cm_${cm.id}`} text={cm.description} />
        </div>);
      });

      return(<React.Fragment key={risk.id}>
        <section className="risk" aria-expanded={risk.expanded}>
          <div id={`risk_${risk.id}_body`} className="risk-header" onClick={_this.handleClick} data-action="toggle-risk" style={{cursor: "pointer"}}>
            <h2>{risk.name}</h2>
            <span className="expander-icon open"><i className="arrow down"></i></span>
            <span className="expander-icon close"><i className="arrow up"></i></span>
          </div>

          <div className="risk-body">
            <div className="id_field">
              <Label htmlFor={`risk_${risk.id}_id`} text={risk.persisted ? `<b>ID:</b> ${risk.id.toString()}` : ""} />
              <Hidden id={`risk_${risk.id}_id`} value={risk.id.toString()} />
            </div>

            <div className="name_field">
              <Label htmlFor={`risk_${risk.id}_name`} text={"Name"} />
              <input
                className="" 
                type="text"
                id={`risk_${risk.id}_name`}
                name={`risks[${risk.id}][name]`}
                value={risk.name}
                onChange={_this.handleChange}
                data-field="name"
                data-id={risk.id}
              />
            </div>

            <div className="type_field">
              <Label htmlFor={`risk_${risk.id}_type`} text={"Type"} />
              <select 
                className=""
                id={`risk_${risk.id}_type`}
                name={`risks[${risk.id}][type]`}
                value={risk.riskType || ""}
                onChange={_this.handleChange}
                data-field="riskType"
                data-id={risk.id}
              >
                <option value="">Choose one...</option>
                <option value="Job Site">Job Site</option>
                <option value="Office/Trailer">Office/Trailer</option>
              </select>
            </div>

            <div className="type_field">
              <Label htmlFor={`risk_${risk.id}_learn_more`} text={"Learn More"} />
              <div 
                className="wysiwyg-preview"
                onClick={_this.handleClick}
                id={`risk_${risk.id}_learn_more_launcher`}
                data-action="open-modal"
                dangerouslySetInnerHTML={{__html: risk.learnMore}}
              ></div>
            </div>
        
            <div className="control_measure_fields">
              Control Measures<br />
              {controlMeasures}
            </div>
          </div>
        </section>
      </React.Fragment>);
    });

    return(<React.Fragment>
      <h1>Manage Risks</h1>
      {risks}
      {modal}
    </React.Fragment>)
  };
}

AdminRisksPage.propTypes = {
  risks: PropTypes.array.isRequired
};
