import React     from "react";
import PropTypes from "prop-types";

export default class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = { checked: this.props.checked };
    this.handleChange = this.handleChange.bind(this);
  };

  componentDidUpdate() {
    if(this.props.risk) {
      this.props.risk.checkSelections();
    }

    if(this.props.other) {
      this.props.other.checkOther();
    }
  };

  componentWillUnmount(){
    if(this.props.root) {
      this.props.root.updateSaveData(this.exportData());
    }
  };

  handleChange(e) {
    this.setState(Object.assign({}, this.state, {checked: !this.state.checked}));
  };

  exportData(){
    return {
      [this.props.id]: this.state.checked
    }
  };

  render() {
    return (<React.Fragment>
      <input type="checkbox" checked={this.state.checked} value={"1"} id={this.props.id} onChange={this.handleChange} className={this.props.classes} />
      <span className="checkmark" onClick={this.handleChange}></span>
    </React.Fragment>);
  };
}

Checkbox.propTypes = {
  id:       PropTypes.string.isRequired,
  checked:  PropTypes.bool.isRequired,
  classes:  PropTypes.string,
  risk:     PropTypes.object,
  root:     PropTypes.object,
  owner:    PropTypes.object,
  other:    PropTypes.object
};
