import React from "react";
import PropTypes from "prop-types";

import ControlMeasure from "./ControlMeasure";
import Text from "../form/Text";
import Textarea from "../form/Textarea";
import Other from "../form/Other";

export default class Risk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      expanded: false
    };

    this.handleClick = this.handleClick.bind(this);

    this.container = React.createRef();
  }

  componentDidMount() {
    this.checkSelections();
  }

  componentDidUpdate() {
    this.checkSelections();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.expanded !== nextState.expanded;
  }

  handleClick(e) {
    let target;
    if (e.target.dataset.hasOwnProperty("action")) {
      target = e.target;
    } else {
      target = e.target.parentElement;
    }

    switch (target.dataset.action) {
      case "toggle-risk":
        this.setState(
          Object.assign({}, this.state, { expanded: !this.state.expanded })
        );
        break;
    }
  }

  checkSelections() {
    const checked = this.container.current.querySelectorAll(
      "input[type='checkbox']:checked"
    );

    if (this.state.checked === false && checked.length > 0) {
      this.setState(Object.assign({}, this.state, { checked: true }));
      this.forceUpdate();
    } else if (this.state.checked === true && checked.length === 0) {
      this.setState(Object.assign({}, this.state, { checked: false }));
      this.forceUpdate();
    }
  }

  render() {
    const _this = this;

    const controlMeasureIds = this.props.root.props.controlMeasureRisks
      .filter(function(cmr) {
        return cmr.riskId === _this.props.id;
      })
      .map(function(cmr) {
        return parseInt(cmr.controlMeasureId);
      });

    const controlMeasures = this.props.root.props.controlMeasures.filter(
      function(cm) {
        return controlMeasureIds.indexOf(parseInt(cm.id)) > -1;
      }
    );

    const textareaId = `s${this.props.root.state.step}_r${this.props.id}_more`;

    let checkmark;
    if (this.state.checked) {
      checkmark = (
        <span className="edit">
          <div className="checkmark_circle"></div>
          <div className="checkmark_stem"></div>
          <div className="checkmark_kick"></div>
        </span>
      );
    }

    return (
      <React.Fragment>
        <section
          className="risk true"
          ref={this.container}
          aria-expanded={this.state.expanded}
        >
          <div
            className="risk-header"
            onClick={this.handleClick}
            data-action="toggle-risk"
            style={{ cursor: "pointer" }}
          >
            {checkmark}
            <h2>
              {this.props.name}
              <a
                onClick={this.props.root.handleClick}
                data-id={this.props.learnMoreId}
                data-action="open-modal"
                className="more"
              >
                i
              </a>
            </h2>
            <span className="expander-icon open">
              <i className="arrow down"></i>
            </span>
            <span className="expander-icon close">
              <i className="arrow up"></i>
            </span>
          </div>

          <div className="risk-body">
            {controlMeasures.map(cm => (
              <ControlMeasure
                key={cm.id}
                risk={this}
                id={cm.id}
                description={cm.description}
                learnMoreId={cm.learnMoreId}
                root={this.props.root}
              />
            ))}
            <Other root={this.props.root} risk={this} />
            <label htmlFor={textareaId}>Use this space for additional details on how the controls will be used to address the risk.</label>
            <Textarea
              classes="textarea"
              id={textareaId}
              value={this.props.root.state.saveData[textareaId] || ""}
              root={this.props.root}
            />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Risk.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  root: PropTypes.object.isRequired
};
