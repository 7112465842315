import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import RiskStep from "./steps/RiskStep";
import StaticStep from "./steps/StaticStep";
import ResultsStep from "./steps/ResultsStep";

import Button from "./form/Button";
import Checkbox from "./form/Checkbox";
import Label from "./form/Label";
import Radio from "./form/Radio";
import Select from "./form/Select";
import Text from "./form/Text";
import Textarea from "./form/Textarea";
import Other from "./form/Other";

import Modal from "./models/Modal";

export default class PlanPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      message: null,
      changingName: false,
      name: this.props.saveData.hasOwnProperty("name")
        ? this.props.saveData.name
        : "New Plan",
      step:
        Object.keys(this.props.saveData).length === 0
          ? 1
          : parseInt(this.props.saveData.step),
      saveData:
        Object.keys(this.props.saveData).length !== 0
          ? this.props.saveData
          : { step: 1 },
      persisted: Object.keys(this.props.saveData).length !== 0,
      modalContent: null, // Set to a string to display the modal window.
      okToPreview: true // Toggles to true when the plan preview is ready
    };

    this.changeName = this.changeName.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.questionsFor = this.questionsFor.bind(this);
    this.risksFor = this.risksFor.bind(this);

    this.nameRef = React.createRef();
  }

  /**
   * The trix editor doesn't have an option to open links in new windows, so do it with an event listener.
   */
  openLinksInNewWindows = function() {
    document.querySelectorAll(".modal-window a").forEach(function(link) {
      if (link.target === "") {
        link.target="_blank";
      }
    });
  };

  componentDidMount() {
    this.openLinksInNewWindows();
  }

  /**
   * Save the plan when changing pages and remove the save message after 5 seconds.
   */
  componentDidUpdate(prevProps, prevState) {
    const _this = this;

    this.openLinksInNewWindows();

    // Save the plan and scroll to the top when the step changes
    if (prevState.step !== this.state.step) {
      this.savePlan(prevState.step);
      window.scrollTo(0, 0);
    }

    // Save the plan when the name changes
    if (prevState.name !== this.state.name) {
      this.savePlan(prevState.step);
    }

    // Hide the 'Plan Saved!' message after 5 seconds
    if (this.state.message) {
      setTimeout(function () {
        _this.setState(Object.assign({}, _this.state, { message: null }));
      }, 5000);
    }
  }

  handleClick(e) {
    let target;
    if (e.target.dataset.hasOwnProperty("action")) {
      target = e.target;
    } else {
      target = e.target.parentElement;
    }

    switch (target.dataset.action) {
      case "close-modal":
        this.setState(Object.assign({}, this.state, { modalContent: null }));
        break;

      case "open-modal":
        let learnMore;
        switch (this.state.step) {
          case 1:
          case 2:
            learnMore = this.props.learnMore.find(
              lm => lm.id == e.target.dataset.id // The data-id attribute is the learnMoreId
            );
            break;

          case 3:
          case 4:
          case 5:
          case 6:
            learnMore = this.props.learnMore.find(
              lm => lm.target == e.target.dataset.id
            );
            break;
        }

        const modalContent = (
          <React.Fragment>
            <div
              dangerouslySetInnerHTML={{ __html: learnMore.description }}
            ></div>
            <button
              onClick={this.handleClick}
              data-action="close-modal"
              id={`lm_${learnMore.id}_close_modal`}
              className="btn prev"
            >
              Close
            </button>
          </React.Fragment>
        );

        this.setState(
          Object.assign({}, this.state, { modalContent: modalContent })
        );
        break;
    }
  }

  changeName(e) {
    e.preventDefault();

    if (this.state.changingName) {
      this.setState(
        Object.assign({}, this.state, {
          changingName: false,
          name: this.nameRef.current.state.value
        })
      );
    } else {
      this.setState(Object.assign({}, this.state, { changingName: true }));
    }
  }

  /**
   * Returns the data for the current step to be saved.
   * Some data is held outside of saveData, which is why we need this function.
   * @param {number} step - The step that needs to be saved.
   * @return {object} An object ready to be saved to the database.
   */
  getSaveData(step) {
    const _this = this;

    const data = Object.keys(this.state.saveData)
      .filter(function (key) {
        return key.indexOf(`s${step}`) > -1;
      })
      .reduce(function (obj, key) {
        obj[key] = _this.state.saveData[key];
        return obj;
      }, {});

    return Object.assign(data, {
      name: this.state.name,
      step: this.state.step
    });
  }

  /**
   * Goes to the specified step, if allowed.
   * @param {string} step - Can be a number (1-6), "previous", or "next"
   */
  goToStep(step) {
    const _this = this;
    let newStep, allowed = false;

    // You can bounce around between steps 1/2 as much as you want; this accounts for all the ways you can do that
    if (this.state.step > 2 || (step === 'previous' && this.state.step === 2) || (step === 'next' && this.state.step === 1)) {
      allowed = true;
    } else {
      // Look for a checked control measure in the save data
      for(const [key, value] of Object.entries(this.state.saveData)) {
        if(key.match(/s[12]_r\d+_cm\d+/)) { // This is save data for a control measure
          if(value === true) {
            allowed = true;
          }
        }
      }

      // Look for checked control measures in the current scene as a last resort.
      if(allowed === false) {
        allowed = document.querySelectorAll('input[type="checkbox"]:checked').length > 0;
      }
    }

    if (allowed) {
      switch (step) {
        case "next":
          newStep = this.state.step + 1;
          break;
        case "previous":
          newStep = this.state.step - 1;
          break;
        default:
          newStep = parseInt(step);
      }

      this.setState(Object.assign({}, this.state, { step: newStep }));
    } else {
      window.scrollTo(0, 0);
      this.setState(Object.assign({}, this.state, { message: "You must select at least one control measure." }));
    }

  }

  /**
   * Returns the input element for a static question.
   * @param {object} question - The json representing this question.
   * @return {JSX.Element} The HTML for the input.
   */
  inputFor(question) {
    const _this = this;
    const value = this.state.saveData[question.id];

    switch (question.type) {
      case "checkbox":
        return (
          <Checkbox
            id={question.id}
            checked={value === true}
            classes={"text"}
            root={this}
          />
        );

      case "label": // label indicates that there are multiple fields for the response
        return (
          <React.Fragment>
            {question.options.map(q => _this.renderStaticQuestion(q))}
          </React.Fragment>
        );

      case "radio":
        return (
          <Radio
            id={question.id}
            value={value || ""}
            classes={"radio"}
            options={question.options}
            root={this}
          />
        );

      case "select":
        return (
          <Select
            id={question.id}
            value={value || ""}
            classes={"select"}
            options={question.options}
            empty={true}
            root={this}
          />
        );

      case "text":
        return (
          <Text
            id={question.id}
            value={value || ""}
            classes={"text"}
            root={this}
          />
        );

      case "textarea":
        return (
          <Textarea
            id={question.id}
            value={value || ""}
            classes={"text"}
            root={this}
          />
        );

      case "Other":
        const fakeRisk = {
          checkSelections: function () { },
          props: { id: question.id }
        };

        return (<Other root={this} risk={fakeRisk} />);
    }
  }

  /**
   * Returns questions from this.props.questions that match the given step.
   * @param {integer} step - Either 3, 4, 5, or 6
   * @return {array} - the questions for the step
   */
  questionsFor(step) {
    return this.props.questions[step];
  }

  /**
   * Turn a json object into a form field.
   * @param {object} question - The json representing this question.
   * @return {JSX.Element} The HTML for the form field.
   */
  renderStaticQuestion(question) {
    let label;
    if (question.label) {
      label = (<Label htmlFor={question.id} text={question.label} />);
    }

    const learnMore = this.props.learnMore.find(lm => lm.target == question.id);

    let openLearnMore;
    if (learnMore) {
      openLearnMore = (<a onClick={this.handleClick} data-id={question.id} data-action="open-modal" className="more">i</a>);
    }

    switch (question.type) {
      case "checkbox":
        return (
          <div className="form-field" key={`container-${question.id}`}>
            <div className="control-measure static">
              {this.inputFor(question)}
              {label}
              {openLearnMore}
            </div>
          </div>
        );
        break;

      case "radio":
        return (
          <div className="form-field" key={`container-${question.id}`}>
            {label}
            {openLearnMore}
            <div className="radio-buttons">
              {this.inputFor(question)}
            </div>
          </div>
        );
        break;

      default:
        return (
          <div className="form-field" key={`container-${question.id}`}>
            {label}
            {openLearnMore}
            {this.inputFor(question)}
          </div>
        );
    }
  }

  /**
   * Returns elements from this.props.risks that match the given name.
   * @param {string} name - either "Job Site" or "Office/Trailer"
   * @return {array} - the risks for the given name
   */
  risksFor(name) {
    return this.props.risks.filter(risk => risk.riskType === name);
  }

  /**
   * Makes an AJAX request to save the plan and sets the user's history to the correct URL.
   * @param {number} step - The step that needs to be saved.
   */
  savePlan(step) {
    const _this = this;
    const persisted = this.state.persisted;

    let url, method;
    if (this.state.persisted) {
      url = `/plans/${this.state.id}`;
      method = "PATCH";
    } else {
      url = "/plans";
      method = "POST";
    }

    this.state.okToPreview = false; // Don't generate output for results while saving.

    axios({
      url: url,
      method: method,
      data: {
        plan: { json: this.getSaveData(step), user_id: this.props.userId }
      },
      headers: {
        "X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content
      }
    }).then(function (response) {
      _this.state.okToPreview = true;
      if (persisted === false) {
        _this.setState(
          Object.assign({}, _this.state, {
            id: response.data.id,
            persisted: true,
          })
        );
        window.history.pushState("", "", `/plans/${response.data.id}/edit`);
      }
    }).catch(function (error) {
      _this.state.okToPreview = true;
      _this.setState(Object.assign({}, _this.state, { message: "Cannot save plan. Please try again." }));
    });
  }

  /**
   * Updates this.state.saveData with the data argument.
   * @param {object} data - A key/value store of data to update.
   **/
  updateSaveData(data) {
    this.state.saveData = Object.assign({}, this.state.saveData, data);
  }

  render() {
    const _this = this;
    let modal;
    if (this.state.modalContent) {
      modal = <Modal owner={this}> {this.state.modalContent} </Modal>;
    }

    let name;
    if (this.state.changingName) {
      name = (
        <React.Fragment>
          <Text
            id={"name"}
            value={this.state.name}
            ref={this.nameRef}
            root={this}
            classes={"text"}
          />
          <Button text="Save" onClick={this.changeName} />
        </React.Fragment>
      );
    } else {
      name = (
        <React.Fragment>
          <h1>{this.state.name}</h1>
          <Button text="Click here to change plan name" onClick={this.changeName} />
        </React.Fragment>
      );
    }

    // Using 'next' and 'previous' as the step will bypass risk/cm validation for steps 1 and 2.
    const step1Link = this.state.step === 2 ? "previous" : 1; 
    const step2Link = this.state.step === 1 ? "next" : 2;

    return (
      <React.Fragment>
        <nav id="breadcrumbs">
          <ul>
            <li><a onClick={() => _this.goToStep(step1Link)}>Step 1: Job Site</a></li>
            <li><a onClick={() => _this.goToStep(step2Link)}>Step 1: Office/Trailer</a></li>
            <li><a onClick={() => _this.goToStep(3)}>Step 2: Screening Employees</a></li>
            <li><a onClick={() => _this.goToStep(4)}>Step 3: Training</a></li>
            <li><a onClick={() => _this.goToStep(5)}>Step 4: Complete Your Plan</a></li>
          </ul>
        </nav>
        <form className="plan">
          <div id="plan-name">
            <div className="wrap">{name}</div>
          </div>

          <div className="message">{this.state.message}</div>
          {eval(`this.renderStep${this.state.step}()`)}
        </form>
        {modal}
      </React.Fragment>
    );
  }

  renderStep1() {
    return (
      <RiskStep
        step={1}
        root={this}
        risks={this.risksFor("Job Site")}
        riskType="Job Site Exposure Risks and Controls"
        buttonText={"NEXT: Office/Trailer Exposure Risks and Controls"}
      />
    );
  }

  renderStep2() {
    return (
      <RiskStep
        step={2}
        root={this}
        risks={this.risksFor("Office/Trailer")}
        riskType="Office/Trailer"
        buttonText={"NEXT: Step 2 Screening Employees"}
      />
    );
  }

  renderStep3() {
    return (
      <StaticStep
        step={3}
        root={this}
        questions={this.questionsFor(3)}
        title={
          "Step 2: Identify the procedures for screening employees and dealing with sick or exposed workers."
        }
        introText={
          "Please use the space provided to describe the specific approaches and policies you will implement for dealing with sick or exposed workers to prevent the spread of disease. The blue information icon will take you to additional information."
        }
        buttonText={"NEXT: Step 3 Training"}
      />
    );
  }

  renderStep4() {
    return (
      <StaticStep
        step={4}
        root={this}
        questions={this.questionsFor(4)}
        title={
          "Step 3: Training on COVID-19"
        }
        introText={
          "Please use the space provided to describe the COVID-19 focused training that will be provided to employees. The blue information icon will take you to training resources to help you carry out this training."
        }
        buttonText={"NEXT: Step 4 Complete Your Plan"}
      />
    );
  }

  renderStep5() {
    return (
      <StaticStep
        step={5}
        root={this}
        questions={this.questionsFor(5)}
        title={"Step 4: Complete Your Plan."}
        introText={
          "Please use the following spaces to provide information on the project, who will be responsible for implementing the plan, and other actions you plan to take to prevent the spread of COVID-19 on the job."
        }
        buttonText={"NEXT: Get Your Plan"}
      />
    );
  }

  renderStep6() {
    return <ResultsStep step={6} root={this} />;
  }
}

PlanPage.propTypes = {
  step: PropTypes.string,
  questions: PropTypes.object.isRequired,
  risks: PropTypes.array.isRequired,
  controlMeasures: PropTypes.array.isRequired,
  controlMeasureRisks: PropTypes.array.isRequired,
  userId: PropTypes.number
};
